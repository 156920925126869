<template>
  <div class="cart pa-3 pa-md-5">
    <!-- <section
      class="px-3 pt-md-3 pb-0 px-md-5 pt-md-5 pb-md-0 mt-0 mt-md-0 mt-lg-0"
    > -->
    <NavbarOne
      class="card-top d-md-none d-lg-none"
      id="card-top"
      :showNavbar="showNavbar"
      :showCart="showCart"
      :showCartDesktop="showCartDesktop"
      :showSearch="showSearch"
      :showLogo="showLogo"
    >
    </NavbarOne>
    <!-- </section> -->

    <section class="mt-16 mt-md-0 pt-3 pt-md-0">
      <v-card class="card-top pa-3 pa-md-5">
        <v-row ref="form">
          <v-col cols="12" lg="8">
            <v-card
              class="card pa-3 mb-3 pa-md-5 mb-md-5"
              outlined
              elevation="1"
            >
              <div class="mb-5">
                <heading-card
                  bold
                  color="dark-blue"
                  :title="$t('page.cart.customer_information')"
                />
              </div>
              <v-row v-if="profile != null">
                <v-col cols="12" sm="6">
                  <v-text-field
                    :value="profile.first_name"
                    :label="$t('field.first_name')"
                    outlined
                    hide-details
                    disabled
                    filled
                    dense
                    class="font-size-14"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    :value="profile.last_name"
                    :label="$t('field.last_name')"
                    outlined
                    hide-details
                    disabled
                    filled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    :value="profile.email"
                    :label="$t('field.email')"
                    outlined
                    hide-details
                    disabled
                    filled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    :value="profile.phone"
                    :label="$t('field.phone')"
                    outlined
                    hide-details
                    disabled
                    filled
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card>
            <v-card
              class="card pa-3 mb-3 pa-md-5 mb-md-5"
              outlined
              elevation="1"
            >
              <div>
                <heading-card
                  bold
                  color="dark-blue"
                  class="mb-5"
                  :title="$t('page.office_care.branch_appointment')"
                />
              </div>
              <v-row>
                <v-col cols="12" :sm="cartSubscribe ? 12 : 6">
                  <v-select
                    :items="branch"
                    ref="branch"
                    :label="$t('field.branch')"
                    item-text="name"
                    item-value="id"
                    v-model="payload.branch"
                    dense
                    outlined
                    :hide-details="!formHasErrors"
                    :rules="[
                      v => !!v || `${$t('field.branch')} ${$t('is_required')}`,
                    ]"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" v-if="!cartSubscribe">
                  <v-menu
                    v-model="opendatepicker"
                    :close-on-content-click="false"
                    :close-on-click="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateFormatted"
                        ref="booking_date"
                        :label="$t('field.booking_date')"
                        outlined
                        :hide-details="!formHasErrors"
                        append-icon="mdi-calendar-range"
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="[
                          v =>
                            !!v ||
                            `${$t('field.booking_date')} ${$t('is_required')}`,
                        ]"
                      ></v-text-field>
                    </template>

                    <c-datepicker
                      :plant="payload.branch"
                      checkQueue
                      :minDate="minDate"
                      :value="payload.booking_date"
                      :holiday="getHoliday"
                      :enableDays="getEnableDays"
                      @changeValue="payload.booking_date = $event"
                      @change="opendatepicker = false"
                    ></c-datepicker>
                  </v-menu>
                </v-col>
                <v-col cols="12" v-if="!cartSubscribe">
                  <v-textarea
                    ref="note"
                    v-model="payload.note"
                    outlined
                    rows="2"
                    hide-details
                    :label="$t('page.cart.order_comment')"
                    :placeholder="`${$t('type_here')}...`"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card>
            <voucher
              :email="profile ? profile.email : null"
              :branch="payload.branch"
              v-model="activeVoucher"
            ></voucher>
          </v-col>
          <v-col cols="12" lg="4">
            <v-card
              class="card pa-3 mb-3 pa-md-5 mb-md-5"
              outlined
              elevation="1"
            >
              <div class="d-flex justify-space-between align-content-start">
                <heading-card
                  :class="{ 'mb-4': !collapse.order }"
                  bold
                  color="dark-blue"
                  :title="$t('page.cart.order_review')"
                />
                <div @click="collapse.order = !collapse.order">
                  <icons
                    icon="icon-arrow-collapse"
                    :class="{ 'collapse--active': collapse.order }"
                  />
                </div>
              </div>
              <template v-if="!collapse.order">
                <template v-if="this.$store.state.cart.length > 0">
                  <div
                    v-for="(item, index) in this.$store.state.cart"
                    :key="index"
                  >
                    <!-- cart product -->
                    <card-cart-item
                      v-if="item.product != null"
                      :name="`${item.product.name}`"
                      :available="item.product.available"
                      :availableGender="item.available_gender"
                      :price="
                        item.product.available
                          ? `IDR ${formatPrice(
                              getInvoiceById(item.product.id).final_price
                            )}`
                          : ''
                      "
                      :realPrice="
                        item.product.available
                          ? `${
                              getInvoiceById(item.product.id).item_applied == 1
                                ? `IDR ${formatPrice(
                                    getInvoiceById(item.product.id).price_total
                                  )}`
                                : ''
                            }`
                          : ''
                      "
                      @close="removeItem(item)"
                    />
                    <!-- cart product package non sub-->
                    <card-cart-item
                      v-else-if="!item.package.subscription"
                      :name="item.package.name"
                      :available="item.package.available"
                      :availableGender="item.available_gender"
                      :price="
                        item.package.available
                          ? `IDR ${formatPrice(
                              getInvoiceById(item.package.id).final_price
                            )}`
                          : ''
                      "
                      :realPrice="
                        item.package.available
                          ? `${
                              getInvoiceById(item.package.id).item_applied == 1
                                ? `IDR ${formatPrice(
                                    getInvoiceById(item.package.id).price_total
                                  )}`
                                : ''
                            }`
                          : ''
                      "
                      @close="removeItem(item)"
                    />
                    <!-- cart product package deal-->
                    <card-cart-item
                      v-else
                      :name="item.package.name"
                      :price="
                        item.deal == null
                          ? `IDR ${formatPrice(
                              item.package.prices[0].amount_per_unit
                            )}`
                          : `IDR ${formatPrice(
                              item.package.prices[0].amount_after_deal
                            )}`
                      "
                      :realPrice="
                        item.deal == null
                          ? null
                          : `IDR ${formatPrice(
                              item.package.prices[0].amount_before_deal
                            )}`
                      "
                      :detailDeal="item.deal"
                      @close="removeItem(item)"
                    />
                  </div>
                </template>
                <p class="count-item" v-else>
                  {{
                    this.$store.state.cart != null &&
                    this.$store.state.cart.length > 0
                      ? `${this.$store.state.cart.length} ${$t(
                          "page.cart.items_in_cart"
                        )}`
                      : $t("page.cart.empty_cart")
                  }}
                </p>
              </template>
            </v-card>
            <v-card
              class="card pa-3 mb-3 pa-md-5 mb-md-5"
              outlined
              elevation="1"
              v-if="availablePackage != null && availablePackage.length > 0"
            >
              <div class="d-flex justify-space-between align-content-start">
                <heading-card
                  :class="{ 'mb-4': !collapse.package }"
                  bold
                  color="dark-blue"
                  :title="$t('page.cart.my_package')"
                />
                <div @click="collapse.package = !collapse.package">
                  <icons
                    icon="icon-arrow-collapse"
                    :class="{ 'collapse--active': collapse.package }"
                  />
                </div>
              </div>
              <template v-if="!collapse.package">
                <card-cart-item
                  v-for="(item, index) in availablePackage"
                  :key="index"
                  :name="`${item.name} ( ${item.qty}x )`"
                  hideClose
                  md
                >
                  <template v-slot:side-content>
                    <span
                      @click="setSelectedPackage(item)"
                      class="package"
                      :class="{
                        'package--use': !isSelectedPackage(item),
                        'package--cancel': isSelectedPackage(item),
                      }"
                    >
                      {{ isSelectedPackage(item) ? $t("cancel") : $t("use") }}
                    </span>
                  </template>
                </card-cart-item>
              </template>
            </v-card>
            <v-card class="card pa-3 mb-1 pa-md-5" outlined elevation="1">
              <div class="d-flex justify-space-between align-content-start">
                <heading-card
                  :class="{ 'mb-4': !collapse.payment }"
                  bold
                  color="dark-blue"
                  :title="$t('page.cart.billing_summary')"
                />
                <div @click="collapse.payment = !collapse.payment">
                  <icons
                    icon="icon-arrow-collapse"
                    :class="{ 'collapse--active': collapse.payment }"
                  />
                </div>
              </div>
              <template v-if="!collapse.payment">
                <div class="billing-box">
                  <div class="d-flex justify-space-between mb-3">
                    <heading-price
                      :text="$t('page.cart.subtotal')"
                      position="left"
                      color="gray"
                    />
                    <heading-price
                      :text="`IDR ${formatPrice(this.subTotal)}`"
                      position="right"
                      color="gray"
                      bold
                    />
                  </div>
                  <div
                    class="d-flex justify-space-between mb-3"
                    v-if="getTotalPromo > 0"
                  >
                    <heading-price text="Promo" position="left" color="gray" />
                    <heading-price
                      :text="`IDR - ${formatPrice(getTotalPromo)}`"
                      position="right"
                      color="gray"
                      bold
                    />
                  </div>
                  <div
                    class="d-flex justify-space-between mb-3"
                    v-if="getTotalVoucher > 0"
                  >
                    <heading-price
                      :text="$t('page.cart.voucher')"
                      position="left"
                      color="gray"
                    />
                    <heading-price
                      :text="`IDR - ${formatPrice(getTotalVoucher)}`"
                      position="right"
                      color="gray"
                      bold
                    />
                  </div>
                  <!-- hide tax -->
                  <!-- <div class="d-flex justify-space-between mb-3">
                    <heading-price
                      :text="$t('page.cart.tax')"
                      position="left"
                      color="gray"
                    />
                    <heading-price
                      :text="`IDR ${formatPrice(this.tax)}`"
                      position="right"
                      color="gray"
                      bold
                    />
                  </div> -->
                </div>
              </template>
              <div class="d-flex justify-space-between mt-3 mb-2">
                <heading-price
                  extraBold
                  :text="$t('page.cart.grand_total')"
                  position="left"
                  color="main"
                />
                <heading-price
                  :text="`IDR ${formatPrice(this.getTotalPayment)}`"
                  position="right"
                  color="main"
                  extraBold
                />
              </div>
              <v-checkbox
                v-model="checkTnc"
                dense
                hide-details
                class="d-flex align-start mb-3 pa-0"
              >
                <template v-slot:label>
                  <div class="checkbox--booking-else">
                    {{ $t("page.cart.by_clic_pay") }}
                    <tnc />
                  </div>
                </template>
              </v-checkbox>

              <c-button
                bold
                shadow
                rounded
                fluid
                @click="payment"
                type="submit"
                :disabled="
                  this.$store.state.cart.length == 0 || !show || !checkTnc
                "
              >
                {{ $t("pay") }} IDR {{ formatPrice(getTotalPayment) }}</c-button
              >
            </v-card>
          </v-col>
        </v-row>
        <v-dialog v-model="dialogConfirmation" persistent max-width="290">
          <v-card class="pa-3">
            <div class="d-flex justify-center mb-3">
              <v-icon x-large color="yellow"> mdi-information-outline </v-icon>
            </div>
            <p class="text-center">
              {{ $t("some_product_not_available_in_this_branch") }}
            </p>
            <div class="d-flex justify-center">
              <c-button
                class="mx-2 py-2"
                rounded
                @click="
                  () => {
                    dialogConfirmation = false;
                    setBranchSetCart();
                  }
                "
                >{{ $t("continue") }}</c-button
              >
              <c-button
                class="mx-2 py-2"
                rounded
                outline
                @click="
                  () => {
                    dialogConfirmation = false;
                    this.payload.branch = tempBranch.previousVal;
                    this.$router.push(`/${this.$i18n.locale}/patient/test`);
                  }
                "
                >{{ $t("edit") }}</c-button
              >
              <c-button
                class="mx-2 py-2"
                rounded
                outline
                @click="
                  () => {
                    dialogConfirmation = false;
                    this.payload.branch = tempBranch.previousVal;
                  }
                "
                >{{ $t("cancel") }}</c-button
              >
            </div>
          </v-card>
        </v-dialog>
      </v-card>
    </section>
  </div>
</template>

<script>
import HeadingCard from "@/components/Heading/HeadingCard.vue";
import Icons from "@/components/elements/Icons.vue";
import CardCartItem from "@/components/v2/card/card_cart_item.vue";
import HeadingPrice from "@/components/Heading/HeadingPrice.vue";
import CButton from "@/components/v2/button/index.vue";
// import Voucher from "@/components/v2/voucher/index.vue";
import Tnc from "@/components/v2/tnc/index.vue";
import API from "@/service/api_service";
import Auth from "@/service/auth_service";
import moment from "moment-timezone";
import CDatepicker from "@/components/v3/date/custom-datepicker.vue";
import NavbarOne from "@/components/v2/topbar/navbar_one.vue";
import Voucher from "@/components/v3/voucher.vue";
import "@/assets/css/row-col-mod.scss";
export default {
  metaInfo() {
    return {
      script: [
        {
          src: process.env.VUE_APP_MIDTRANS_URL,
          "data-client-key": process.env.VUE_APP_MIDTRANS_CLIENT_KEY,
          defer: true,
          callback: () => this.$store.commit("loadMidtrans"),
        },
      ],
    };
  },
  components: {
    HeadingCard,
    Icons,
    CardCartItem,
    HeadingPrice,
    CButton,
    Voucher,
    Tnc,
    NavbarOne,
    CDatepicker,
  },
  data: () => ({
    voucherCode: "",
    activeVoucher: null,
    checkTnc: false,
    branch: [],
    profile: null,
    checkbox: false,
    payload: {
      booking_date: "",
      branch: "",
      note: "",
      family_id: "",
    },
    collapse: {
      order: false,
      payment: false,
      package: false,
      voucher: false,
    },
    show: true,
    listDiscount: null,
    invoice: null,
    subTotal: 0,
    totalPayment: 0,
    totalDiscount: 0,
    tax: 0,
    opendatepicker: false,
    opendatepickerBirthDate: false,
    formHasErrors: false,
    formRegisterHasErrors: false,
    errorMessages: "",
    midtransStatus: 406,
    payloadForOther: {
      idcard: "",
      firstname: "",
      lastname: "",
      birthDate: "",
      birthPlace: "",
      identityType: 128,
      identityCountry: 102,
      relation: "",
      selectGender: "",
    },
    showCart: true,
    showCartDesktop: true,
    showSearch: true,
    showLogo: false,
    showNavbar: true,
    notForMe: false,
    myFamily: [],
    dialog: false,
    openFormRegister: false,
    gender: [],
    identityType: [],
    identityCountry: [],
    dialogMenu: false,
    availablePackage: [],
    selectedPackage: [],
    tempBranch: {
      currentVal: "",
      previousVal: "",
    },
    dialogConfirmation: false,
    linkPaymentSuccess: "payment-success",
    linkPaymentPending: "payment-pending",
    homeCare: "false",
    cartSubscribe: false,
    cartDeal: false,
    // start ignore regis 5 PM - 8 PM
    ignoreRegisSameDay: false,
    // end ignore regis 5 PM - 8 PM
    // start available date
    availableDates: [],
    // end available date
  }),
  computed: {
    getTotalVoucher() {
      if (this.activeVoucher) {
        if (this.activeVoucher.option_value == "fix_amount") {
          return parseFloat(this.activeVoucher.amount) >
            parseFloat(this.subTotal)
            ? parseFloat(this.subTotal)
            : parseFloat(this.activeVoucher.amount);
        } else {
          const totalVoucher =
            parseFloat(this.subTotal) *
            parseFloat(this.activeVoucher.percentage / 100);
          if (totalVoucher > parseFloat(this.activeVoucher.amount_max)) {
            return parseFloat(this.activeVoucher.amount_max);
          } else {
            return totalVoucher;
          }
        }
      }
      return 0;
    },
    getTotalPromo() {
      return 0;
    },
    getTotalPayment() {
      return (
        parseFloat(this.subTotal) -
        parseFloat(this.getTotalVoucher) -
        parseFloat(this.getTotalPromo)
      );
    },
    // start holiday
    getHoliday() {
      const indexPlant = this.branch.findIndex(
        res => res.id == this.payload.branch
      );
      if (indexPlant > -1) {
        return this.branch[indexPlant].upcoming_holidays;
      }
      return [];
    },
    // end holiday

    // start enable days
    getEnableDays() {
      const indexPlant = this.branch.findIndex(
        res => res.id == this.payload.branch
      );
      let enableDays = [];
      if (indexPlant > -1) {
        const days = [
          "Minggu",
          "Senin",
          "Selasa",
          "Rabu",
          "Kamis",
          "Jumat",
          "Sabtu",
        ];
        const { opening_hours } = this.branch[indexPlant];
        for (let i = 0; i < days.length; i++) {
          const indexDays = opening_hours.findIndex(
            item => item.day == days[i]
          );
          if (indexDays > -1) {
            enableDays.push(i);
          }
        }
      }
      return enableDays;
    },
    // end enable days

    minDate() {
      let currentTime = moment();
      const indexPlant = this.branch.findIndex(
        res => res.id == this.payload.branch
      );
      // check timezone time
      if (this.payload.branch != null && this.payload.branch != "") {
        if (indexPlant > -1) {
          const timezone = moment.tz.guess();
          if (this.branch[indexPlant].timezone_area == timezone) {
            currentTime = moment();
          } else {
            const now = moment().format("HH:mm:ss");
            currentTime = moment(
              this.convertTime(
                now,
                moment.tz.guess(),
                this.branch[indexPlant].timezone_area
              ),
              "YYYY-MM-DD HH:mm:ss"
            );
          }
        }
      }
      // Set the start and end time boundaries
      let startTime = "";
      let endTime = "";

      if (indexPlant > -1) {
        const { opening_hours } = this.branch[indexPlant];
        const indexByDayName = opening_hours.findIndex(
          item => item.day == currentTime.locale("ID").format("dddd")
        );
        if (indexByDayName > -1) {
          const close = opening_hours[indexByDayName].hours
            .split("-")[1]
            .replace(":00", "");
          startTime = moment()
            .hour(0) // mulai jam 00:00
            .minute(0)
            .second(0);
          endTime = moment().hour(close).minute(0).second(0);
        }
      }
      // Check if the current time is between the start and end time
      const isWithinTimeRange =
        indexPlant > -1 ? currentTime.isBetween(startTime, endTime) : true;

      // Output the result
      let add = 0;
      while (
        add < 10 &&
        this.getHoliday.some(
          data =>
            data.date ==
            currentTime.clone().add(add, "days").format("YYYY-MM-DD")
        )
      ) {
        add++;
      }
      if (!isWithinTimeRange) {
        return moment()
          .add(add + 1, "days")
          .format("YYYY-MM-DD");
      } else {
        return moment().add(add, "days").format("YYYY-MM-DD");
      }
    },
    dataCart() {
      return this.$store.state.cart;
    },
    dateFormatted() {
      return this.payload.booking_date
        ? this.formatDate(this.payload.booking_date)
        : "";
    },
    birthDateFormatted() {
      return this.payloadForOther.birthDate == ""
        ? ""
        : this.formatDate(this.payloadForOther.birthDate);
    },
    form() {
      return {
        booking_date: this.payload.booking_date,
        branch: this.payload.branch,
        family_id: this.payload.family_id,
      };
    },
    formRegister() {
      return {
        regster_first_name: this.payloadForOther.firstname,
        regster_last_name: this.payloadForOther.lastname,
        regster_identity_type: this.payloadForOther.identityType,
        regster_identity_number: this.payloadForOther.idcard,
        regster_identity_country: this.payloadForOther.identityCountry,
        register_birth_date: this.payloadForOther.birthDate,
        regster_gender: this.payloadForOther.selectGender,
        regster_relation: this.payloadForOther.relation,
      };
    },
  },
  watch: {
    payload: {
      handler() {
        this.show = true;
      },
      deep: true,
    },
    "payload.branch": async function (val, prevVal) {
      this.tempBranch = { previousVal: prevVal, currentVal: val };
      if (val != "") {
        let item = [...this.$store.state.cart];
        if (item.length > 0) {
          let idProduct = [];
          let idPackage = [];
          item.forEach(res => {
            if (res.product != null) {
              idProduct.push(res.product.id);
            }
            if (res.package != null) {
              idProduct.push(res.package.id);
            }
          });
          if (await this.checkBranchCover(idProduct, idPackage)) {
            this.setBranchSetCart();
          } else {
            this.dialogConfirmation = true;
          }
        }
      }

      const minDate = this.minDate; // Replace with your minimum date or leave as null
      const maxDate = null; // Replace with your maximum date or leave as null

      this.payload.booking_date = this.findClosestValidDate(minDate, maxDate);
    },
    "payload.booking_date": function () {
      history.replaceState(
        null,
        null,
        `?date=${this.payload.booking_date || ""}`
      );
    },
    notForMe: function (val) {
      if (val) {
        this.getfamily();
      } else {
        this.payload.family_id = "";
        this.getCart();
      }
    },
    "payload.family_id": function (val) {
      if (val) {
        const indexFamily = this.myFamily.findIndex(
          item => item.patient_id == val
        );
        if (indexFamily > -1) {
          this.openFormRegister = false;
          this.payloadForOther = this.myFamily[indexFamily];
        }
        this.getCart();
      }
    },
  },
  async created() {
    await this.getPlant();
    // check cart is subscribe
    if (this.$store.state.cart.length > 0) {
      if (this.$store.state.cart[0].package != null) {
        this.cartSubscribe = this.$store.state.cart[0].package.subscription;
        if (this.cartSubscribe) {
          this.linkPaymentSuccess = "payment-success-subscribe";
          this.linkPaymentPending = "payment-pending-subscribe";
        } else {
          this.linkPaymentSuccess = "payment-success";
          this.linkPaymentPending = "payment-pending";
          this.homeCare = "";
          // this.linkPaymentSuccess = "payment-success-package";
          // this.linkPaymentPending = "payment-pending-package";
        }
      } else {
        this.linkPaymentSuccess = "payment-success";
        this.linkPaymentPending = "payment-pending";
      }
    }

    if (this.$route.query.transaction_status) {
      if (this.$route.query.transaction_status == "pending") {
        this.$router.push(
          `/${this.$i18n.locale}/${this.linkPaymentPending}?order_id=${this.$route.query.order_id}&status_code=${this.$route.query.status_code}&transaction_status=${this.$route.query.transaction_status}&home_care=${this.homeCare}&cart='created'`
        );
      } else {
        this.$router.push(
          `/${this.$i18n.locale}/${this.linkPaymentSuccess}?order_id=${this.$route.query.order_id}&status_code=${this.$route.query.status_code}&transaction_status=${this.$route.query.transaction_status}&home_care=${this.homeCare}`
        );
      }
    }
    const booking_date = moment(this.$route.query.date);
    if (this.$route.query.date != undefined && booking_date.isValid()) {
      this.payload.booking_date = booking_date.format("YYYY-MM-DD");
    } else {
      this.payload.booking_date = this.minDate;
    }

    this.getProfile();
    //this.getCart();
    this.getGender();
    this.getIndentityType();
    this.getIndentityCountry();
    this.getRelationList();
  },
  methods: {
    // start near date
    findClosestValidDate(minDate, maxDate) {
      const startDate = moment(this.payload.booking_date);
      const getEnableDays = this.getEnableDays;

      // Exclude the holiday on August 17th, 2023 (which is a Thursday)
      // getEnableDays.push(moment("2023-08-17").day());

      // return same day when enable
      if (
        getEnableDays.includes(startDate.day()) &&
        (!minDate || startDate.isSameOrAfter(minDate)) &&
        (!maxDate || startDate.isSameOrBefore(maxDate))
      ) {
        return startDate.format("YYYY-MM-DD");
      }
      let closestDateBefore = null;
      let closestDateAfter = null;
      let daysDifferenceBefore = Infinity;
      let daysDifferenceAfter = Infinity;

      for (let daysToAdd = 1; daysToAdd <= 7; daysToAdd++) {
        const candidateDateBefore = startDate
          .clone()
          .subtract(daysToAdd, "days");
        const candidateDateAfter = startDate.clone().add(daysToAdd, "days");

        const isAfterMinDateBefore =
          !minDate || candidateDateBefore.isSameOrAfter(minDate);
        const isBeforeMaxDateBefore =
          !maxDate || candidateDateBefore.isSameOrBefore(maxDate);

        if (
          getEnableDays.includes(candidateDateBefore.day()) &&
          isAfterMinDateBefore &&
          isBeforeMaxDateBefore
        ) {
          const currentDifference = Math.abs(
            candidateDateBefore.diff(startDate, "days")
          );
          if (currentDifference < daysDifferenceBefore) {
            closestDateBefore = candidateDateBefore;
            daysDifferenceBefore = currentDifference;
          }
        }

        const isAfterMinDateAfter =
          !minDate || candidateDateAfter.isSameOrAfter(minDate);
        const isBeforeMaxDateAfter =
          !maxDate || candidateDateAfter.isSameOrBefore(maxDate);

        if (
          getEnableDays.includes(candidateDateAfter.day()) &&
          isAfterMinDateAfter &&
          isBeforeMaxDateAfter
        ) {
          const currentDifference = Math.abs(
            candidateDateAfter.diff(startDate, "days")
          );
          if (currentDifference < daysDifferenceAfter) {
            closestDateAfter = candidateDateAfter;
            daysDifferenceAfter = currentDifference;
          }
        }
      }
      // Compare the closest dates before and after and choose the closer one
      if (closestDateBefore && closestDateAfter) {
        return closestDateBefore.isBefore(closestDateAfter)
          ? closestDateBefore.format("YYYY-MM-DD")
          : closestDateAfter.format("YYYY-MM-DD");
      } else if (closestDateBefore) {
        return closestDateBefore.format("YYYY-MM-DD");
      } else if (closestDateAfter) {
        return closestDateAfter.format("YYYY-MM-DD");
      } else {
        return null;
      }
    },
    // end near date

    // start convert time
    convertTime(timeString, sourceTimeZone, targetTimeZone) {
      // Parse the original time string in the source timezone
      const originalTime = moment.tz(timeString, "HH:mm:ss", sourceTimeZone);

      // Convert the time to the target timezone
      const convertedTime = originalTime.clone().tz(targetTimeZone);

      // Format the converted time as "HH:mm:ss"
      const formattedTime = convertedTime.format("YYYY-MM-DD HH:mm:ss");

      return formattedTime;
    },
    // end convert time

    // start available date
    allowedDates(a) {
      return this.availableDates.includes(a);
    },
    pickerUpdate: function (val) {
      let totalDay = moment(val, "YYYY-MM").daysInMonth();

      let availableDates = [];

      let monthNow = moment().format("M");
      let monthSelected = moment(val).format("M");
      let day;

      if (monthNow == monthSelected) day = moment().format("D");
      else day = 1;

      for (let i = day; i <= totalDay; i++) {
        let date = moment()
          .month(val.split("-")[1] - 1)
          .date(i)
          .format("YYYY-MM-DD");
        if (moment(date).day() !== 0) availableDates.push(date);
      }
      this.availableDates = availableDates;
      this.allowedDates();
    },
    // end available date

    async setBranchSetCart() {
      const resp = await API.get(
        `${process.env.VUE_APP_API_TRUST}plant/get?show_opening_hours=true`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      );
      if (resp.statusCode == 200) {
        let item = [...this.$store.state.cart];
        // filter sementara disabled selain bali dan nrc
        // this.branch = await resp.results.filter((res) => {
        //   return res.id == 4 || res.id == 12;
        // });
        const indexBranch = this.branch.findIndex(
          item => item.id == this.tempBranch.currentVal
        );
        const newPlant = this.branch[indexBranch];
        item.map(item => {
          item.plant = newPlant;
          item.plant_id = newPlant.id;
          item.package_id = item.package == null ? null : item.package.id;
          item.product_id = item.product == null ? null : item.product.id;
          item.deal_id = item.deal == null ? null : item.deal.id;
        });
        try {
          API.post(
            `${process.env.VUE_APP_API_TRUST}cart/store`,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            },
            item
          ).then(res => {
            if (res.statusCode == 200) {
              this.getCart();
            }
          });
        } catch (e) {
          console.log(e);
        }
      }
    },
    async checkBranchCover(product, paket) {
      try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}plant/get-lab-queue?product_id=${product}&package_id=${paket}&show_opening_hours=true`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        const indexBranch = resp.results.findIndex(
          res => res.id == this.payload.branch
        );

        return indexBranch > -1;
      } catch (error) {
        console.log(error);
      }
      return true;
    },
    getBranch(id) {
      const indexBranch = this.branch.findIndex(item => item.id == id);
      return this.branch[indexBranch];
    },
    sortByName(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    },

    openRegister() {
      this.resetOrderOther();
      this.openFormRegister = true;
      this.payload.family_id = "";
    },
    resetOrderOther() {
      this.payloadForOther = {
        idcard: "",
        firstname: "",
        lastname: "",
        birthDate: "",
        birthPlace: "",
        identityType: 128,
        identityCountry: 102,
        relation: "",
      };
      this.openFormRegister = false;
    },
    async getRelationList() {
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}patient-related/get-rel-type`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        this.relationItem = res.results;
      } catch (error) {
        console.log(error);
      }
    },
    async getIndentityType() {
      try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}identity-type/get?show_default=true`,
          {}
        );
        if (resp.statusCode == 200) {
          this.identityType = resp.results.sort((a, b) =>
            this.sortByName(a, b)
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getIndentityCountry() {
      try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}country/get`,
          {}
        );
        if (resp.statusCode == 200) {
          this.identityCountry = resp.results.sort((a, b) =>
            this.sortByName(a, b)
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getGender() {
      try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}gender/get`,
          {}
        );

        if (resp.statusCode == 200) {
          this.gender = resp.results.sort((a, b) => this.sortByName(a, b));
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getPlant() {
      const resp = await API.get(
        `${process.env.VUE_APP_API_TRUST}plant/get?show_opening_hours=true`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      );
      if (resp.statusCode == 200) {
        this.branch = resp.results;
      }
    },
    async getProfile() {
      try {
        const resp = await API.get(`${process.env.VUE_APP_API_TRUST}profile`, {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        });
        this.profile = {
          email: resp.person.personal_info.contacts.email,
          first_name: resp.person.first_name,
          last_name: resp.person.last_name,
          phone: resp.person.personal_info.contacts.mobile_phone_number,
          id: resp.person.patient.id,
        };
        this.getCart();
      } catch (error) {
        console.log(error);
      }
    },
    async getCart() {
      try {
        await this.$store.dispatch("fetchCartData");
        let idProduct = [];
        let type = "product";
        this.$store.state.cart.forEach(res => {
          if (res.product != null) {
            idProduct.push(res.product.id);
            type = "product";
          }
          if (res.package != null) {
            idProduct.push(res.package.id);
            type = "package";
          }
        });
        if (this.$store.state.cart.length > 0) {
          this.payload.branch = this.$store.state.cart[0].plant.id;
          this.getDiscount();
        }
        this.calculatePayment();
        if (idProduct.length > 0) {
          this.getAvailableGender(idProduct, this.$store.state.cart, type);
        }

        const indexCartTest = this.$store.state.cart.find(
          res => res.package === null
        );
        if (typeof indexCartTest !== "undefined") {
          this.linkPaymentSuccess = "payment-success";
          this.linkPaymentPending = "payment-pending";
        }

        if (typeof indexCartTest === "undefined") {
          const indexCartNonSubs = this.$store.state.cart.find(
            res => res.package.subscription === false
          );
          if (typeof indexCartNonSubs !== "undefined") {
            this.linkPaymentSuccess = "payment-success";
            this.linkPaymentPending = "payment-pending";
            this.homeCare = "";
            // this.linkPaymentSuccess = "payment-success-package";
            // this.linkPaymentPending = "payment-pending-package";
          }
          const indexCartSubs = this.$store.state.cart.find(
            res => res.package.subscription === true
          );
          if (typeof indexCartSubs !== "undefined") {
            this.linkPaymentSuccess = "payment-success-subscribe";
            this.linkPaymentPending = "payment-pending-subscribe";
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getAvailableGender(datProdId, itemCart, type) {
      //console.log(datProdId,'asdsadsdsad')
      let profileId = "";
      if (this.notForMe == true && this.payload.family_id != "") {
        profileId = this.payload.family_id;
      } else {
        profileId = this.profile.id;
      }
      const dataReady =
        type == "product"
          ? [
              {
                patient_id: profileId,
                product_id: datProdId,
              },
            ]
          : [
              {
                patient_id: profileId,
                package_id: datProdId,
              },
            ];
      try {
        const resp = await API.post(
          `${process.env.VUE_APP_API_TRUST}registration/validation-book`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          },
          dataReady
        );
        const availableGender = itemCart.map(obj => {
          const findIndexGender = resp.results[0].valid_product.findIndex(
            item =>
              obj.product != null
                ? item.id == obj.product.id
                : item.id == obj.package.id
          );
          obj.available_gender = findIndexGender > -1;
          return obj;
        });
        this.$store.commit("updateCart", availableGender);
        if (this.$store.state.cart.length > 0) {
          this.payload.branch = this.$store.state.cart[0].plant.id;
          this.getDiscount();
        }
        this.calculatePayment();
      } catch (error) {
        console.log(error);
      }
    },
    getInvoiceById(id) {
      if (typeof this.invoice !== "undefined" && this.invoice != null) {
        if (this.invoice.length > 0) {
          const index = this.invoice.findIndex(item => item.id == id);
          if (index >= 0) {
            return this.invoice[index];
          }
        }
      }
      return { final_price: 0, item_applied: 0, price_total: 0 };
    },
    formatPrice(price) {
      return `${Intl.NumberFormat().format(price)}.00`;
    },
    async removeItem(data) {
      let idplant = data.plant.id;
      let idproduct = data.product != null ? data.product.id : data.package.id;
      const dataReady = [
        {
          plant_id: idplant,
          package_id: data.product != null ? null : idproduct,
          from_package: false,
          deal_id: data.deal == null ? null : data.deal.id,
          product_id: data.product != null ? idproduct : null,
          qty: 0,
        },
      ];
      try {
        const resp = await API.post(
          `${process.env.VUE_APP_API_TRUST}cart/store`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          },
          dataReady
        );
        if (resp.statusCode == 200) {
          if (this.$store.state.cart.length > 1) {
            this.payload.branch = this.$store.state.cart[0].plant.id;
          } else {
            this.payload.branch = "";
            this.listDiscount = null;
          }
          this.getCart();
        } else if (resp.statusCode == 403) {
          Object.keys(resp.errors).forEach(element => {
            this.toast(
              "error",
              typeof resp.errors[element] == "object"
                ? resp.errors[element][0]
                : resp.errors[element]
            );
          });
        } else {
          this.$swal({
            icon: "error",
            text: resp.message,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getDiscount() {
      this.listDiscount = null;
      const filterProduct = this.$store.state.cart.filter(item => {
        return item.product == null
          ? item.package.available
          : item.product.available;
      });
      if (filterProduct.length > 0) {
        const type = filterProduct[0].product != null ? "product" : "package";
        const product = filterProduct.map(obj => {
          if (obj.product != null) {
            return obj.product.id;
          } else {
            return obj.package.id;
          }
        });
        const payloadDiscount = {
          ids: product,
          plant_ids: [this.payload.branch],
          type,
        };
        try {
          const resp = await API.post(
            `${process.env.VUE_APP_API_TRUST}discount/get`,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            },
            payloadDiscount
          );
          if (resp.statusCode == 200 && resp.results.length > 0) {
            this.listDiscount = resp.results;
          }
        } catch (error) {
          console.log(error);
        }
      }
    },

    // calculate payment
    async calculatePayment() {
      if (this.$store.state.cart.length > 0) {
        // non sub
        if (
          this.$store.state.cart[0].product != null ||
          (this.$store.state.cart[0].package != null &&
            !this.$store.state.cart[0].package.subscription)
        ) {
          let product = this.$store.state.cart.map(obj => {
            obj.id = obj.product != null ? obj.product.id : obj.package.id;
            return obj;
          });
          const filterProduct = product.filter(item => {
            return item.product != null
              ? item.product.available
              : item.package.available;
          });
          // const payload = {
          //   plant_id: this.payload.branch,
          //   type: product[0].product != null ? "product" : "package",
          //   items: filterProduct,
          //   vouchers: [],
          // };
          if (filterProduct.length) {
            // if (this.activeVoucher.length > 0) {
            //   try {
            //     const resp = await API.post(
            //       `${process.env.VUE_APP_API_TRUST}discount/apply?lang=${this.$i18n.locale}`,
            //       {
            //         "Content-Type": "application/json",
            //         Authorization: `Bearer ${Auth.getToken()}`,
            //       },
            //       payload
            //     );
            //     if (resp.statusCode == 200) {
            //       resp.results.map(item => {
            //         const indexPackage = this.selectedPackage.findIndex(
            //           selected => selected.id == item.id
            //         );
            //         if (indexPackage > -1) {
            //           item.final_price = 0;
            //           item.isFromPackage = true;
            //           item.item_applied = 1;
            //         } else {
            //           item.isFromPackage = false;
            //         }
            //         return item;
            //       });

            //       this.invoice = resp.results;
            //       this.totalPayment = 0;
            //       this.totalDiscount = 0;
            //       this.subTotal = 0;
            //       this.invoice.forEach(item => {
            //         if (!item.isFromPackage) {
            //           this.totalPayment = this.totalPayment + item.final_price;
            //           this.totalDiscount =
            //             this.totalDiscount + item.discount_amount;
            //           this.subTotal = this.subTotal + item.price_total;
            //         }
            //       });
            //       this.toast("success", this.$t("page.cart.success_voucher"));
            //     } else if (resp.statusCode == 403) {
            //       this.toast("error", this.$t("page.cart.failed_voucher"));

            //       // Object.keys(resp.errors).forEach(element => {
            //       //   this.toast(
            //       //     "error",
            //       //     typeof resp.errors[element] == "object"
            //       //       ? resp.errors[element][0]
            //       //       : resp.errors[element]
            //       //   );
            //       // });
            //     } else {
            //       this.$swal({
            //         icon: "error",
            //         text: resp.message,
            //       });
            //     }
            //   } catch (error) {
            //     console.log(error);
            //   }
            // } else {
            this.totalPayment = 0;
            this.totalDiscount = 0;
            this.subTotal = 0;
            this.invoice = [];
            filterProduct.forEach(item => {
              this.totalPayment =
                item.package == null
                  ? this.totalPayment +
                    parseFloat(
                      item.product.prices[
                        item.product.prices.findIndex(
                          res => res.payment_method_id == 1
                        )
                      ].amount
                    )
                  : parseFloat(item.package.prices[0].amount_per_unit);
              this.subTotal = this.totalPayment;
              this.invoice = [
                ...this.invoice,
                {
                  id: item.id,
                  final_price:
                    item.package == null
                      ? item.product.prices[
                          item.product.prices.findIndex(
                            res => res.payment_method_id == 1
                          )
                        ].amount
                      : item.package.prices[0].amount_per_unit,
                },
              ];
            });
            // }
          } else {
            // if subscribe
            this.invoice = null;
            this.totalPayment = 0;
            this.totalDiscount = 0;
            this.subTotal = 0;
          }
        } else {
          const filterProduct = this.$store.state.cart.filter(data => {
            return data.package.available;
          });
          this.invoice = filterProduct;
          this.totalPayment = 0;
          await this.$store.state.cart.forEach(dataCart => {
            if (dataCart.package.available) {
              if (dataCart.deal == null) {
                this.totalPayment =
                  dataCart.package.prices[0].amount_per_unit +
                  this.totalPayment;
              } else {
                this.totalPayment =
                  dataCart.package.prices[0].amount_after_deal +
                  this.totalPayment;
              }
            }
          });
          this.totalDiscount = 0;
          this.subTotal = this.totalPayment;
        }
      } else {
        // reset when 0
        this.invoice = null;
        this.totalPayment = 0;
        this.totalDiscount = 0;
        this.subTotal = 0;
      }
    },
    async register() {
      this.formRegisterHasErrors = false;
      Object.keys(this.formRegister).forEach(f => {
        if (!this.formRegister[f]) this.formRegisterHasErrors = true;
        this.$refs[f].validate(true);
      });
      const {
        firstname,
        lastname,
        idcard,
        birthDate,
        selectGender,
        identityType,
        identityCountry,
        relation,
      } = this.payloadForOther;
      const dataReady = {
        first_name: firstname,
        last_name: lastname,
        identity_number: idcard,
        identity_type: identityType,
        identity_country_issuer: identityCountry,
        date_of_birth: birthDate,
        gender: selectGender,
        relation: relation,
        client_unique_id: "tm617a34c4a3b86",
      };
      try {
        const resp = await await API.post(
          `${process.env.VUE_APP_API_TRUST}register/subaccount`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          },
          dataReady
        );
        if (resp.statusCode === 200) {
          this.$swal({
            text: "Add Sub Account success!",
            confirmButtonColor: "#1A428A",
            confirmButtonText: "Oke",
          }).then(result => {
            if (result.isConfirmed) {
              this.payloadForOther = resp.results.persona;
              this.payload.family_id = resp.results.persona.patient_id;
              this.getfamily();
            }
          });
        } else if (resp.statusCode == 400) {
          Object.keys(resp.errors).forEach(element => {
            this.toast(
              "error",
              typeof resp.errors[element] == "object"
                ? resp.errors[element][0]
                : resp.errors[element]
            );
          });
        } else {
          this.$swal({
            icon: "error",
            text: resp.message,
            //text: this.$t("error_try_again_latter"),
          });
        }
      } catch (error) {
        this.$swal({
          icon: "error",
          text: "Error Registration!",
        });
      }
    },
    async payment() {
      this.formHasErrors = false;
      Object.keys(this.form).forEach(f => {
        if (this.notForMe) {
          if (!this.form[f]) this.formHasErrors = true;
          this.$refs[f].validate(true);
        } else {
          if (this.cartSubscribe) {
            if (f != "booking_date" && f != "family_id") {
              if (!this.form[f]) this.formHasErrors = true;
              this.$refs[f].validate(true);
            }
          } else {
            if (f != "family_id") {
              if (!this.form[f]) this.formHasErrors = true;
              this.$refs[f].validate(true);
            }
          }
        }
      });

      if (!this.formHasErrors && this.invoice.length > 0) {
        let productCart = [];
        let packageCart = [];
        this.show = false;
        const filterProduct = this.$store.state.cart.filter(item => {
          return item.product != null
            ? item.product.available
            : item.package.available;
        });
        // start for subscribe
        if (
          this.$store.state.cart[0].package != null &&
          this.$store.state.cart[0].package.subscription
        ) {
          let orders = [];
          filterProduct.forEach(data => {
            orders = [
              ...orders,
              {
                deal_id: data.deal == null ? null : data.deal.id,
                package_id: data.package.id,
                price: data.package.prices[0].amount_per_unit,
                qty: 1,
              },
            ];
          });
          const dataReady = [
            {
              client_unique_id: "tm617a34c4a3b86",
              orders,
              plant_id: this.$store.state.cart[0].plant.id,
            },
          ];
          try {
            const order = await API.post(
              `${process.env.VUE_APP_API_TRUST}subscription/buy-package`,
              {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Auth.getToken()}`,
              },
              dataReady
            );
            if (order.statusCode === 200) {
              if (
                order.results.length > 0 &&
                order.results[0].midtrans_token != null
              ) {
                if (this.$store.state.midtransIsLoaded) {
                  const vuethis = this;
                  window.snap.pay(order.results[0].midtrans_token, {
                    onSuccess: function (result) {
                      if (result != undefined) {
                        vuethis.midtransStatus = result.statusCode;
                        //vuethis.resetCart()
                        vuethis.$router.push(
                          `/${vuethis.$i18n.locale}/${vuethis.linkPaymentSuccess}${result.finish_redirect_url}&home_care=${vuethis.homeCare}`
                        );
                      }
                    },
                    onPending: function (result) {
                      vuethis.midtransStatus = result.statusCode;
                      //api cek status payment
                      API.get(
                        `${process.env.VUE_APP_API_TRUST}registration/status-transaction?invoice_number=${result.order_id}`,
                        {
                          Authorization: `Bearer ${Auth.getToken()}`,
                        }
                      ).then(res => {
                        if (res.status_code == 200) {
                          vuethis.midtransStatus = res.status_code;
                          vuethis.$router.push(
                            `/${vuethis.$i18n.locale}/${vuethis.linkPaymentSuccess}?order_id=${res.order_id}&status_code=${res.status_code}&transaction_status=${res.transaction_status}&home_care=${vuethis.homeCare}`
                          );
                          //  vuethis.resetCart();
                        } else {
                          vuethis.$router.push(
                            `/${vuethis.$i18n.locale}/${vuethis.linkPaymentPending}?order_id=${res.order_id}&status_code=${res.status_code}&transaction_status=${res.transaction_status}&home_care=${vuethis.homeCare}&cart='pending-subs'`
                          );
                          // vuethis
                          //   .$swal({
                          //     text: vuethis.$t("the_order_has_been_saved"),
                          //     showCancelButton: false,
                          //     confirmButtonColor: "#f7685b",
                          //     confirmButtonText: vuethis.$t("close"),
                          //   })
                          //   .then(() => {
                          //     vuethis.$router.push(
                          //       `/${vuethis.$i18n.locale}/${vuethis.linkPaymentPending}?order_id=${res.order_id}&status_code=${res.status_code}&transaction_status=${res.transaction_status}`
                          //     );
                          //     //console.log( vuethis.midtransStatus ,'pending2')
                          //   //  vuethis.resetCart();
                          //   });
                        }
                      });
                    },
                    onClose: function () {
                      vuethis
                        .$swal({
                          text: vuethis.$t("the_order_has_been_saved"),
                          showCancelButton: false,
                          confirmButtonColor: "#f7685b",
                          confirmButtonText: vuethis.$t("close"),
                        })
                        .then(() => {
                          vuethis.resetCart();
                        });
                    },
                    onError: function (result) {
                      vuethis.midtransStatus = result.statusCode;
                      vuethis.$swal({
                        icon: "error",
                        text: vuethis.$t("error_payment"),
                      });
                    },
                  });
                }
              } else {
                this.midtransStatus = 0;
                this.resetCart();
              }
            } else if (order.statusCode == 403) {
              Object.keys(order.errors).forEach(element => {
                this.toast(
                  "error",
                  typeof order.errors[element] == "object"
                    ? order.errors[element][0]
                    : order.errors[element]
                );
              });
            } else {
              this.$swal({
                icon: "error",
                text: order.message,
              });
            }
          } catch (error) {
            this.$swal({
              icon: "error",
              text: this.$t("error_booking"),
            });
          }
        }
        // start for non subscribe
        else {
          filterProduct.forEach(res => {
            const indexPackage = this.selectedPackage.findIndex(
              selected => selected.id == res.id
            );
            if (indexPackage > -1) {
              res.from_package = true;
            }
            if (res.from_package) {
              packageCart = [...packageCart, res];
            } else {
              if (res.product != null) {
                productCart = [
                  ...productCart,
                  { ...res, product_id: res.product.id },
                ];
              } else {
                productCart = [
                  ...productCart,
                  { ...res, package_id: res.package.id },
                ];
              }
            }
          });
          let detail_order_paket = [];
          const productCartDisc = productCart.map(resdisc => {
            const indexdisc = this.invoice.findIndex(
              item => item.id == resdisc.id
            );
            if (indexdisc >= 0) {
              resdisc.voucher = this.invoice[indexdisc].voucher;
              resdisc.discount_amount =
                this.invoice[indexdisc].voucher == null
                  ? null
                  : this.invoice[indexdisc].discount_amount;
            }

            return resdisc;
          });
          const dataReady = [
            {
              booking_date: moment(this.payload.booking_date).format(
                "YYYY-MM-DD"
              ),
              amount: this.totalPayment,
              notes: this.payload.note,
              plant_id: this.$store.state.cart[0].plant.id,
              description: this.payload.note ? this.payload.description : "-",
              product_order_detail:
                this.$store.state.cart[0].product != null
                  ? productCartDisc
                  : null,
              product_package_detail: detail_order_paket,
              package_id: productCartDisc[0].package_id,
              voucher: productCartDisc[0].voucher,
              discount_amount: productCartDisc[0].discount_amount,
              voucher_payment: this.activeVoucher
                ? {
                    voucher_release_id: this.activeVoucher.voucher_release_id,
                    code: this.activeVoucher.code,
                  }
                : null,
            },
          ];

          // start check booking time
          let currentTime = moment();
          // check timezone time
          if (this.payload.branch != null && this.payload.branch != "") {
            const indexBranch = this.branch.findIndex(
              item => item.id == this.payload.branch
            );
            if (indexBranch > -1) {
              const timezone = moment.tz.guess();
              if (this.branch[indexBranch].timezone_area == timezone) {
                currentTime = moment();
              } else {
                const now = moment().format("HH:mm:ss");
                currentTime = moment(
                  this.convertTime(
                    now,
                    moment.tz.guess(),
                    this.branch[indexBranch].timezone_area
                  ),
                  "YYYY-MM-DD HH:mm:ss"
                );
              }
            }
          }
          // Set the start and end time boundaries
          let startTime = "";
          let endTime = "";
          const indexPlant = this.branch.findIndex(
            res => res.id == this.payload.branch
          );
          if (indexPlant > -1) {
            const { opening_hours } = this.branch[indexPlant];
            const indexByDayName = opening_hours.findIndex(
              item => item.day == currentTime.locale("ID").format("dddd")
            );
            if (indexByDayName > -1) {
              const close = opening_hours[indexByDayName].hours
                .split("-")[1]
                .replace(":00", "");
              startTime = moment()
                .hour(close - 2) // mulai jam 2 jam sebelum tutup warning
                .minute(0)
                .second(0);
              endTime = moment().hour(close).minute(0).second(0);
            }
          }
          // Check if the current time is between the start and end time
          const isWithinTimeRange = currentTime.isBetween(startTime, endTime);
          if (
            !this.ignoreRegisSameDay &&
            isWithinTimeRange &&
            currentTime.format("DD-MM-YYYY") ==
              moment(this.payload.booking_date).format("DD-MM-YYYY")
          ) {
            await this.$swal({
              icon: "warning",
              text: `${this.$t(
                "page.cart.notif_open_hours_start"
              )} ${endTime.format("HH:mm")}. ${this.$t(
                "page.cart.notif_open_hours_end"
              )}`,
              confirmButtonColor: "#00a4b3",
              showCancelButton: true,
              cancelButtonColor: "#ff0000",
              confirmButtonText: this.$t("oke"),
              cancelButtonText: this.$t("cancel"),
              allowOutsideClick: false,
            }).then(result => {
              if (result.isConfirmed) {
                this.ignoreRegisSameDay = true;
                return "exit";
              }
            });
          } else {
            this.ignoreRegisSameDay = true;
          }
          // end check book time

          // submit reagis order
          if (this.ignoreRegisSameDay) {
            try {
              const order = await API.post(
                `${process.env.VUE_APP_API_TRUST}registration/book`,
                {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${Auth.getToken()}`,
                },
                dataReady
              );
              if (order.statusCode === 200) {
                if (
                  order.results.length > 0 &&
                  order.results[0].midtrans_token != null &&
                  this.getTotalPayment != 0
                ) {
                  if (this.$store.state.midtransIsLoaded) {
                    const vuethis = this;
                    window.snap.pay(order.results[0].midtrans_token, {
                      onSuccess: function (result) {
                        if (result != undefined) {
                          vuethis.midtransStatus = result.statusCode;
                          //vuethis.resetCart()
                          vuethis.$router.push(
                            `/${vuethis.$i18n.locale}/${vuethis.linkPaymentSuccess}${result.finish_redirect_url}&home_care=${vuethis.homeCare}`
                          );
                        }
                      },
                      onPending: function (result) {
                        vuethis.midtransStatus = result.statusCode;
                        //api cek status payment
                        API.get(
                          `${process.env.VUE_APP_API_TRUST}registration/status-transaction?invoice_number=${result.order_id}`,
                          {
                            Authorization: `Bearer ${Auth.getToken()}`,
                          }
                        ).then(res => {
                          if (res.status_code == 200) {
                            vuethis.midtransStatus = res.status_code;
                            vuethis.$router.push(
                              `/${vuethis.$i18n.locale}/${vuethis.linkPaymentSuccess}?order_id=${res.order_id}&status_code=${res.status_code}&transaction_status=${res.transaction_status}&home_care=${vuethis.homeCare}`
                            );
                            //  vuethis.resetCart();
                          } else {
                            vuethis.$router.push(
                              `/${vuethis.$i18n.locale}/${vuethis.linkPaymentPending}?order_id=${res.order_id}&status_code=${res.status_code}&transaction_status=${res.transaction_status}&home_care=${vuethis.homeCare}&cart='pending-non-subs'`
                            );
                            // vuethis
                            //   .$swal({
                            //     text: vuethis.$t("the_order_has_been_saved"),
                            //     showCancelButton: false,
                            //     confirmButtonColor: "#f7685b",
                            //     confirmButtonText: vuethis.$t("close"),
                            //   })
                            //   .then(() => {
                            //     vuethis.$router.push(
                            //       `/${vuethis.$i18n.locale}/${vuethis.linkPaymentPending}?order_id=${res.order_id}&status_code=${res.status_code}&transaction_status=${res.transaction_status}`
                            //     );
                            //     //console.log( vuethis.midtransStatus ,'pending2')
                            //   //  vuethis.resetCart();
                            //   });
                          }
                        });
                      },
                      onClose: function () {
                        vuethis
                          .$swal({
                            text: vuethis.$t("the_order_has_been_saved"),
                            showCancelButton: false,
                            confirmButtonColor: "#f7685b",
                            confirmButtonText: vuethis.$t("close"),
                          })
                          .then(() => {
                            vuethis.resetCart();
                          });
                      },
                      onError: function (result) {
                        vuethis.midtransStatus = result.statusCode;
                        vuethis.$swal({
                          icon: "error",
                          text: vuethis.$t("error_payment"),
                        });
                      },
                    });
                  }
                } else {
                  this.$router.push(
                    `/${this.$i18n.locale}/${this.linkPaymentSuccess}?order_id=${order.results[0].invoice_number}&order_number=${order.results[0].order_number}&transaction_status=settlement&home_care=false`
                  );
                }
              } else if (order.statusCode == 400) {
                Object.keys(order.errors).forEach(element => {
                  this.toast(
                    "error",
                    typeof order.errors[element] == "object"
                      ? order.errors[element][0]
                      : order.errors[element]
                  );
                });
              } else {
                this.$swal({
                  icon: "error",
                  text: order.message,
                });
              }
            } catch (error) {
              this.$swal({
                icon: "error",
                text: this.$t("error_booking"),
              });
            }
          }
        }
        // end non sub
      }
    },
    async resetCart() {
      // reset cart

      try {
        const resp = await API.post(
          `${process.env.VUE_APP_API_TRUST}cart/empty`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          },
          {}
        );

        if (resp.statusCode == 200) {
          this.getCart();
          if (this.midtransStatus == 200) {
            this.$router.push(
              `/${this.$i18n.locale}/${this.linkPaymentSuccess}&home_care=${this.homeCare}`
            );
          } else if (this.midtransStatus == 0) {
            this.$router.push(`/${this.$i18n.locale}/use-success`);
          } else {
            this.$router.push(
              `/${this.$i18n.locale}/patient/profile?tab=paymentinfo`
            );
          }
        } else if (resp.statusCode == 400) {
          Object.keys(resp.errors).forEach(element => {
            this.toast(
              "error",
              typeof resp.errors[element] == "object"
                ? resp.errors[element][0]
                : resp.errors[element]
            );
          });
        } else {
          this.$swal({
            icon: "error",
            text: resp.message,
            //text: this.$t("error_try_again_latter"),
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    formatDate(date) {
      return moment(date)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },

    async getfamily() {
      try {
        let resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}patient-related/list`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        this.myFamily = resp.results.filter(res => res.relation != "-");
      } catch (error) {
        console.log(error);
      }
    },

    async openDialog() {
      this.dialogMenu = true;
    },
    toggleDialogMenu(toggle) {
      this.dialogMenu = toggle;
    },
    setSelectedPackage(item) {
      const indexPackage = this.selectedPackage.findIndex(
        res => res.id == item.id
      );
      if (indexPackage == -1) {
        this.selectedPackage.push(item);
      } else {
        this.selectedPackage.splice(indexPackage, 1);
      }
      this.calculatePayment();
    },
    isSelectedPackage(item) {
      const indexPackage = this.selectedPackage.findIndex(
        res => res.id == item.id
      );
      return indexPackage != -1;
    },
    toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.cart {
  .package {
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    &--use {
      color: $main_2;
    }
    &--cancel {
      color: red;
    }
  }
  .register-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: gray;
    span {
      color: $main_2;
      cursor: pointer;
    }
  }
  .count-item {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
  }
  .billing-box {
    border-bottom: 1px solid #e0e0e0;
  }
  .checkbox {
    &--booking-else {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: gray;
      text-transform: none;
    }
  }
  .voucher {
    margin-bottom: 20px;
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
  .collapse--active {
    transform: rotate(180deg);
  }
  a {
    text-decoration: none;
  }
}

.card-cart {
  width: auto;
  height: 60px;
}

.text-field {
  max-width: 70%;
  margin-left: 10px;
}

.cart-right {
  text-align: right;
  justify-content: flex-end;
  background: none;
  width: 95%;
  margin-left: 379px;
  margin-right: 10px;
  margin-top: -10px;
}

@media only screen and (max-width: 600px) {
  .text-field {
    max-width: 75%;
    margin-left: 5px !important;
    margin-top: 0px;
  }
  .bell-mobile {
    margin-left: 8px;
  }

  .user-card {
    max-width: 85% !important;
    margin-top: 0px;
    margin-left: -2px;
    margin-right: -84px;
  }
  .notification {
    border-radius: 3px;
    height: 46px;
    margin-top: -2px;
    margin-left: -33px;
  }

  .cart-right {
    z-index: 1;
    margin-left: -10px;
  }
  .card-cart {
    position: sticky;
    top: 0;
    z-index: 999;
  }
}
</style>

<style lang="scss">
.cart {
  .v-text-field {
    &.v-input--dense {
      input {
        font-size: 14px !important;
      }
    }
  }
  .v-select {
    &.v-input--dense {
      font-size: 14px !important;
    }
  }
}
</style>
